import {
  ListMemberRole_default
} from "./chunk-QI4JFA5A.js";
import {
  MemberRoleViewModelContextProvider
} from "./chunk-C6FULZAJ.js";

// src/pages/MemberRole/index.tsx
import React from "react";
import { observer } from "mobx-react";
var MemberRolePage = observer(
  class MemberRolePage2 extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return /* @__PURE__ */ React.createElement("div", { className: "px-3 py-4" }, /* @__PURE__ */ React.createElement(MemberRoleViewModelContextProvider, null, /* @__PURE__ */ React.createElement(ListMemberRole_default, null)));
    }
  }
);

export {
  MemberRolePage
};
