import {
  ListPermission_default
} from "./chunk-BF76PBAZ.js";
import {
  PermissionViewModelContextProvider
} from "./chunk-YGQFF5A7.js";

// src/pages/Permission/index.tsx
import React from "react";
import { observer } from "mobx-react";
var PermissionPage = observer(
  class PermissionPage2 extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return /* @__PURE__ */ React.createElement("div", { className: "px-3 py-4" }, /* @__PURE__ */ React.createElement(PermissionViewModelContextProvider, null, /* @__PURE__ */ React.createElement(ListPermission_default, null)));
    }
  }
);

export {
  PermissionPage
};
