import {
  notify
} from "./chunk-DL5QNKBI.js";
import {
  history
} from "./chunk-OZ3CUKHD.js";

// src/auth/index.ts
import { AesirxAuthenticationApiService, AUTHORIZATION_KEY, Storage } from "aesirx-lib";
var login = async ({ username, password }) => {
  try {
    document.body.classList.add("body_login_page");
    const authService = new AesirxAuthenticationApiService();
    const result = await authService.login(username, password);
    if (result) {
      Storage.setItem("auth", true);
      document.body.classList.remove("body_login_page");
      history.push("/");
      return true;
    } else {
      notify("Login information is incorrect", "error");
      document.body.classList.remove("body_login_page");
      return false;
    }
  } catch (error) {
    return false;
  }
};
var logout = () => {
  localStorage.clear();
  history.push("/login");
};
var isLogin = () => {
  try {
    const isAuthenticated = Storage.getItem("auth");
    const userID = Storage.getItem(AUTHORIZATION_KEY.MEMBER_ID);
    const userName = Storage.getItem(AUTHORIZATION_KEY.MEMBER_EMAIL);
    if (isAuthenticated && userID && userName) {
      return true;
    }
    return false;
  } catch (error) {
    logout();
  }
};

export {
  login,
  logout,
  isLogin
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */
